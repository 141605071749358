<template>
  <div class="OverviewKeyNumbers">
    <!-- Categories -->
    <div class="Box">
      <span
        v-if="affectedCategories !== false"
        class="Number">{{ affectedCategories }}</span>
      <span class="Text">
        {{
          affectedCategories === 1
            ? mixWB('AFFECTED_BUILDING_PART').toLowerCase()
            : mixWB('AFFECTED_BUILDING_PARTS').toLowerCase()
        }}
      </span>
    </div>

    <!-- Types -->
    <div class="Box">
      <span class="Number">{{ screeningTypes && screeningTypes.length }}</span>
      <span class="Text">
        {{ screeningTypes.length === 1
          ? mixWB('ADDED_TYPE').toLowerCase()
          : mixWB('ADDED_TYPES').toLowerCase()
        }}
      </span>
    </div>

    <!-- Samples -->
    <div class="Box">
      <div
        class="InnerBox"
        v-if="samplesStatus.total === 0">
        <span class="Number">0</span>
        <span class="Text">{{ mixWB('DONE_SAMPLES').toLowerCase() }}</span>
      </div>
      <div
        class="InnerBox"
        v-if="samplesStatus.filled">
        <span class="Number">{{ samplesStatus.filled }}</span>
        <span class="Text">
          {{
            samplesStatus.filled === 1
              ? mixWB('DONE_SAMPLE').toLowerCase()
              : mixWB('DONE_SAMPLES').toLowerCase()
          }}
        </span>
      </div>
      <div
        v-if="samplesStatus.equivalent"
        class="InnerBox">
        <span class="Number">{{ samplesStatus.equivalent }}</span>
        <span class="Text">
          {{
            samplesStatus.equivalent === 1
              ? mixWB('EQUIVALENT_SAMPLE').toLowerCase()
              : mixWB('EQUIVALENT_SAMPLES').toLowerCase()
          }}
        </span>
      </div>
      <div
        v-if="samplesStatus.skipped"
        class="InnerBox">
        <span class="Number">{{ samplesStatus.skipped }}</span>
        <span class="Text">
          {{
            samplesStatus.skipped === 1
              ? mixWB('ASSESSED_SAMPLE').toLowerCase()
              : mixWB('ASSESSED_SAMPLES').toLowerCase()
          }}
        </span>
      </div>
      <div
        v-if="samplesStatus.postponed"
        class="InnerBox">
        <span class="Number">{{ samplesStatus.postponed }}</span>
        <span class="Text">
          {{
            samplesStatus.skipped === 1
              ? mixWB('POSTPONED_SAMPLE').toLowerCase()
              : mixWB('POSTPONED_SAMPLES').toLowerCase()
          }}
        </span>
      </div>
      <div
        v-if="samplesStatus.existingResults"
        class="InnerBox">
        <span class="Number">{{ samplesStatus.existingResults }}</span>
        <span class="Text">
          {{
            samplesStatus.skipped === 1
              ? mixWB('EXISTING_SAMPLE').toLowerCase()
              : mixWB('EXISTING_SAMPLES').toLowerCase()
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OverviewKeyNumbers',
  computed: {
    ...mapGetters([
      'samplesStatus',
      'screeningTypes',
    ]),
    affectedCategories() {
      if (!this.screeningTypes) {
        return false
      }

      const categories = this.screeningTypes.reduce((prev, item) => {
        if (!prev.includes(item.categoryID)) {
          prev.push(item.categoryID)
        }
        return prev
      }, [])

      return categories.length
    },
  },
}
</script>

<style lang="stylus" scoped>
  .OverviewKeyNumbers
    display flex
    +below($phablet)
      display block

  .Box
    flex-basis 0
    flex-grow 1
    padding 15px 0px 20px
    border-right 1px solid $color_grey_lighter
    flex-center-children-column()
    justify-content center
    &:last-child
      border-right none
    .Number
      font-size 2.5rem
    .Text
      font-size 1.125rem
    .InnerBox
      flex-center-children-column()
      margin-bottom 10px
      &:last-child
        margin-bottom 0
    +below($phablet)
      border-right none
      border-bottom 1px solid $color_grey_lighter
      &:last-child
        border-bottom none

</style>

<template>
  <form
    class="OverviewMilvaMarket"
    @submit.prevent="onSubmit">
    <MilvaMarketForm
      :marketData="market"
      @market-form-update="onMilvaMarketUpdate" />

    <div class="Footer"/>

    <ErrorText
      :text="errorText" />

    <!-- Button -->
    <div class="ButtonWrap">
      <Button
        type="submit"
        :fullWidth="false"
        :text="mixWB('SAVE')"
        :isDisabled="!isUpdated"
        :isLoading="isLoading" />
    </div>
  </form>
</template>

<script>
import MilvaMarketForm from '@/components/Forms/MilvaMarketForm.vue'
import { mapGetters } from 'vuex'
import Button from '@/components/Buttons/Button.vue'
import ErrorText from '@/components/FormElements/ErrorText.vue'

export default {
  name: 'OverviewMilvaMarket',
  data() {
    return {
      isUpdated: false,
      isLoading: false,
      errorText: '',
      market: {
        isApproved: false,
        contactEmail: '',
        expiryDate: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentScreeningData',
    ]),
  },
  methods: {
    async onSubmit() {
      // Validation
      // - Milva Market
      if (this.market.isApproved) {
        if (!this.market.contactEmail) {
          this.errorText = this.mixWB('ERROR_EMAIL_IS_MISSING')
          return
        }
        if (!this.market.expiryDate) {
          this.errorText = this.mixWB('ERROR_EXPIRY_DATE_MISSING')
          return
        }
      }

      this.isLoading = true

      // Save data
      await this.$store.dispatch('asyncUpdateScreening', { market: { ...this.market } })

      this.isLoading = false
      this.isUpdated = false
    },
    onMilvaMarketUpdate(marketData) {
      this.isUpdated = true
      this.market = marketData
    },
    setDataOnLoad() {
      this.market = {
        isApproved: this.currentScreeningData.market.isApproved,
        contactEmail: this.currentScreeningData.market.contactEmail,
        expiryDate: this.currentScreeningData.market.expiryDate,
      }
    },
  },
  components: {
    MilvaMarketForm,
    Button,
    ErrorText,
  },
  created() {
    this.setDataOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .OverviewMilvaMarket
    display block
    padding 20px

  .ButtonWrap
    display flex
</style>

<template>
  <div class="ProjectOptions">
    <div
      class="InnerWrap">
      <ProjectOptionItem
        :text="mixWB('BUILDER_INFORMATION')"
        name="builder"
        :isDone="!!builder.fullName"
        @item-click="onItemClick" />
      <ProjectOptionItem
        :text="reportDeliveredDateText"
        name="reportDeliveredDate"
        :isDone="!!currentScreeningData.screeningReportDeliveredDate"
        @item-click="onItemClick" />
      <ProjectOptionItem
        :text="reportRevisedDateText"
        name="reportRevisedDate"
        :isDone="!!currentScreeningData.screeningRevisedDate"
        :isRequired="false"
        @item-click="onItemClick" />
      <ProjectOptionItem
        :text="currentScreeningData.getCommentsOnProjectTitle()"
        name="reportNotes"
        :isRequired="false"
        @item-click="onItemClick" />
    </div>

    <Dialog
      :isShowing="showModal"
      :useComponent="modalTouse"
      :componentProps="modalProps"
      :size="modalSize"
      :easyClose="false"
      @builder-updated="onDialogClose"
      @date-update="onDateUpdate"
      @close="onDialogClose" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BuilderModal from '@/components/Modals/BuilderModal.vue'
import DateSelectModal from '@/components/Modals/DateSelectModal.vue'
import ProjectNotesModal from '@/components/Modals/ProjectNotesModal.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import ProjectOptionItem from '@/components/ResultItems/ProjectOptionItem.vue'
import EventBus from '@/EventBus'

export default {
  name: 'ProjectOptions',
  data() {
    return {
      // Modal
      modalTouse: BuilderModal,
      showModal: false,
      modalProps: {},
      modalSize: '',
    }
  },
  computed: {
    ...mapGetters([
      'metaData',
      'buildings',
      'addressImages',
      'addressImagesLoaded',
      'currentScreeningData',
      'currentScreeningMetaDataLoaded',
      'currentScreeningLoaded',
    ]),
    builder() {
      return this.metaData.builder || {}
    },
    reportDeliveredDateText() {
      let date = '-'
      if (this.currentScreeningData.screeningReportDeliveredDate) {
        date = this.mixGetDate(this.currentScreeningData.screeningReportDeliveredDate, 'LL')
      }

      return `${ this.mixWB('DATE_OF_REPORTING') }: <br /> ${ date }`
    },
    reportRevisedDateText() {
      let date = '-'
      if (this.currentScreeningData.screeningRevisedDate) {
        date = this.mixGetDate(this.currentScreeningData.screeningRevisedDate, 'LL')
      }

      return `${ this.mixWB('REVISED_DATE') }: <br /> ${ date }`
    },
  },
  methods: {
    onItemClick(name) {
      if (name === 'builder') {
        this.modalTouse = BuilderModal
        this.showModal = true
        this.modalSize = 'small'
        this.modalProps = {}
      }
      if (name === 'reportDeliveredDate') {
        this.modalTouse = DateSelectModal
        this.showModal = true
        this.modalSize = 'small'
        this.modalProps = {
          modalTitle: this.mixWB('DATE_OF_REPORTING'),
          name: 'reportDeliveredDate',
          startDate: this.currentScreeningData.screeningReportDeliveredDate,
        }
      }
      if (name === 'reportRevisedDate') {
        this.modalTouse = DateSelectModal
        this.showModal = true
        this.modalSize = 'small'
        this.modalProps = {
          modalTitle: this.mixWB('REVISED_DATE'),
          name: 'reportRevisedDate',
          startDate: this.currentScreeningData.screeningRevisedDate,
        }
      }
      if (name === 'reportNotes') {
        this.modalTouse = ProjectNotesModal
        this.showModal = true
        this.modalSize = 'large'
        this.modalProps = {}
      }
    },
    onDateUpdate({ name, value }) {
      this.onDialogClose()

      let propName = ''
      if (name === 'reportDeliveredDate') {
        propName = 'screeningReportDeliveredDate'
      }
      if (name === 'reportRevisedDate') {
        propName = 'screeningRevisedDate'
      }

      if (!propName) {
        return
      }

      this.$store.dispatch('setNewScreeningValues', [
        { propName, value },
      ])
    },
    onDialogClose() {
      this.showModal = false
      EventBus.$emit('page-updated')
    },
  },
  components: {
    ProjectOptionItem,
    Dialog,
  },
  created() {
    EventBus.$on('open-modal', this.onItemClick)
  },
}
</script>

<style lang="stylus" scoped>
  .ProjectOptions
    position relative
    @media print
      display none
</style>

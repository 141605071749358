<template>
  <div class="Overview">
    <Header/>

    <div class="PageContentWrap">
      <div class="InnerWrap">
        <!-- Options -->
        <Accordion
          :title="mixWB('GENERAL_INFORMATION')"
          :body-component="ProjectOptions"
          padding="none" />

        <!-- Milva Market -->
        <Accordion
          v-if="showMilvaMarketOption"
          :title="mixWB('MILVA_MARKET')"
          :bodyComponent="OverviewMilvaMarket"
          padding="none" />

        <!-- Key numbers -->
        <Accordion
          :title="mixWB('KEY_NUMBERS')"
          :bodyComponent="OverviewKeyNumbers"
          padding="none" />

        <!-- Address -->
        <Accordion
          :title="mixWB('ABOUT_THE_PROJECT')"
          :bodyComponent="OverviewAddress"
          padding="none" />
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Accordion from '@/components/Accordion.vue'
import Header from '@/components/ResultItems/Header.vue'
import OverviewAddress from '@/components/ResultItems/OverviewAddress'
import OverviewMilvaMarket from '@/components/ResultItems/OverviewMilvaMarket'
import OverviewKeyNumbers from '@/components/ResultItems/OverviewKeyNumbers'
import ProjectOptions from '@/components/ResultItems/ProjectOptions.vue'

export default {
  name: 'Overview',
  data() {
    return {
      OverviewAddress,
      OverviewMilvaMarket,
      OverviewKeyNumbers,
      ProjectOptions,
    }
  },
  computed: {
    ...mapGetters([
      'selectedUnits',
      'currentScreeningData',
      'currentAccount',
    ]),
    showMilvaMarketOption() {
      // Check for not isScreening and not SSG
      return !this.currentScreeningData.isScreening
        && this.currentAccount.id !== 'uDQYwpFGwFrwkHfaVSWg'
    },
  },
  components: {
    Header,
    Accordion,
  },
}
</script>

<style lang="stylus" scoped>
  .Overview
    display block

  .PageContentWrap
    page-wrap-outer-1()
    padding-top 0

  .InnerWrap
    page-wrap-inner-2()
</style>

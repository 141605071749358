<template>
  <div class="OverviewAddress">
    <!-- Row -->
    <div class="Row">
      <!-- Address -->
      <div class="Block">
        <span class="Title">{{ mixWB('ADDRESS') }}</span>
        <span>
          {{ projectAddress.address }}, {{ projectAddress.postalCode }} {{ projectAddress.city }}
        </span>
      </div>

      <!-- Project description -->
      <div class="Block">
        <span class="Title">{{ mixWB('TYPE_OF_PROJECT') }}</span>
        <span>{{ projectTypeText }}</span>
      </div>
    </div>

    <!-- Row -->
    <div class="Row">
      <!-- Units -->
      <div class="Block">
        <span class="Title">{{ mixWB('UNITS') }}</span>
        <span v-if="!selectedUnitsData.total">-</span>
        <span v-if="selectedUnitsData.buildings">
          {{ mixWB('BUILDINGS') }}: {{ selectedUnitsData.buildings }}
        </span>
        <span v-if="selectedUnitsData.floors">
          {{ mixWB('FLOORS_2') }}: {{ selectedUnitsData.floors }}
        </span>
        <span v-if="selectedUnitsData.apartments">
          {{ mixWB('APARTMENTS') }}: {{ selectedUnitsData.apartments }}
        </span>
      </div>

      <!-- Builder -->
      <div class="Block">
        <span class="Title">{{ mixWB('BUILDER') }}</span>
        <template v-if="builder">
          <span>{{ builder.fullName }}</span>
          <span>{{ builder.phoneNumber }}</span>
          <span>{{ builder.email }}</span>
          <span>{{ builder.address.address }}</span>
          <span>{{ builder.address.postalCode }} {{ builder.address.city }}</span>
        </template>
        <span v-else>-</span>
      </div>
    </div>

    <!-- Images -->
    <div
      v-if="addressProfileImages.length"
      class="ImageListWrap Split">
      <ImageList
        :title="mixWB('PROFILE_IMAGE')"
        :images="addressProfileImages" />
      <ImageList
        :title="mixWB('CADASTRAL_MAP')"
        :images="addressCadastralMapImages" />
    </div>
  </div>
</template>

<script>
import ImageList from '@/components/ResultItems/ImageList.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'OverviewAddress',
  computed: {
    ...mapGetters([
      'currentScreeningData',
      'metaData',
      'addressImages',
      'selectedUnits',
    ]),
    projectAddress() {
      if (!this.currentScreeningData) {
        return {}
      }

      return this.currentScreeningData.address
    },
    builder() {
      return this.metaData.builder || false
    },
    addressProfileImages() {
      if (!this.addressImages?.overview?.profile) {
        return []
      }

      return this.addressImages.overview.profile
    },
    addressCadastralMapImages() {
      if (!this.addressImages?.overview?.cadastralMap) {
        return []
      }

      return this.addressImages.overview.cadastralMap
    },
    projectTypeText() {
      let text = '-'

      if (!this.metaData.projectType) {
        return text
      }

      if (this.metaData.projectType === 'damage-related-screening') {
        text = this.mixWB('DAMAGE_RELATED_ENVIRONMENTAL_SCREENING')
      }
      else if (this.currentScreeningData.isScreening) {
        if (this.metaData.projectType === 'total-demolition') {
          text = this.mixWB('PROJECT_TYPE_SCREENING_OF_DEMOLITION_TEXT')
        }
        if (this.metaData.projectType === 'renovation') {
          text = this.mixWB('PROJECT_TYPE_SCREENING_OF_RENOVATION_TEXT')
        }
      }
      else {
        if (this.metaData.projectType === 'total-demolition') {
          text = this.mixWB('TOTAL_DEMOLITION')
        }
        if (this.metaData.projectType === 'renovation') {
          text = this.mixWB('RENOVATION')
        }
      }

      return text
    },
    selectedUnitsData() {
      const data = {
        total: 0,
        buildings: 0,
        floors: 0,
        apartments: 0,
      }

      this.selectedUnits.forEach((unit) => {
        data.total += 1
        if (unit.type === 'building') {
          data.buildings += 1
        }
        if (unit.type === 'floor') {
          data.floors += 1
        }
        if (unit.type === 'apartment') {
          data.apartments += 1
        }
      })

      return data
    },
  },
  components: {
    ImageList,
  },

}
</script>

<style lang="stylus" scoped>
  .OverviewAddress
    display block

  .Row
    display flex
    justify-content space-between
    border-bottom 1px solid $color_grey_lighter
    span
      display block
      margin-bottom 2.5px
    .Block
      flex-basis 0
      flex-grow 1
      padding 15px 5px 15px 15px
      border-right 1px solid $color_grey_lighter
      &:last-child
        border-right none
    .Title
      font-weight bold
      margin-bottom 7.5px
      text-transform uppercase
    .TextWithTitle
      margin-bottom 10px
      span
        &:first-child
          font-style italic
    +below($phablet)
      display block
      .Block
        border-right none
        border-bottom 1px solid $color_grey_lighter
        &:last-child
          border-bottom none

  .ImageListWrap
    background-color $color_grey_lightest
    &.Split
      +above($tablet)
        display flex

  .CadastralMapWrap
    padding 15px
    border-top 1px solid $color_grey_lighter
    span
      display block
      font-size 0.875rem
      margin-bottom 5px

</style>

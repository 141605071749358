<template>
  <div class="ProjectOptionItem">
    <div
      v-if="isDone"
      class="IconWrap Done">
      <CheckIcon />
    </div>
    <div
      v-else
      class="IconWrap"
      :class="{ HideIcon: !isRequired }">
      <WarningIcon />
    </div>
    <span
      class="Text"
      v-html="text"/>
    <div class="ButtonWrap">
      <Button
        size="small"
        :text="mixWB('EDIT')"
        :fullWidth="false"
        :hasShadow="false"
        @button-click="onButtonClick" />
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/assets/svg/check.svg?inline'
import WarningIcon from '@/assets/svg/warning.svg?inline'
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'ProjectOptionItem',
  props: {
    text: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isDone: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    onButtonClick() {
      this.$emit('item-click', this.name)
    },
  },
  components: {
    CheckIcon,
    WarningIcon,
    Button,
  },
}
</script>

<style lang="stylus" scoped>
  .ProjectOptionItem
    width 100%
    display flex
    align-items center
    padding 10px
    border-bottom 1px solid $color_grey_lighter
    &:last-child
      border-bottom none

  .IconWrap
    box(30px)
    padding 5px
    margin-right 10px
    svg
        fill $color_warning
    &.Done
      svg
        fill $color_primary
    &.HideIcon
      opacity 0

  .Text
    flex-grow 2

  .ButtonWrap
    display flex
    align-items center
    >>> .ButtonWrap
        margin-bottom 0
</style>

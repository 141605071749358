<template>
  <div
    class="ImageList"
    :class="rootClasses">
    <span
      v-if="title"
      class="Title">{{ title }}</span>
    <span
      v-if="!images.length"
      class="EmptyText">{{ mixWB('NO_IMAGES_YET') }}</span>
    <div
      v-if="images.length"
      class="ListWrap">
      <div
        v-for="image in images"
        :key="image.base.path"
        class="ImageWrap">
        <MultiImage
          :image="image"
          transformation="square_200"
          preview />
      </div>
    </div>
  </div>
</template>

<script>
import MultiImage from '@/components/Images/MultiImage.vue'

export default {
  name: 'ImageList',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    images: {
      type: Array,
      required: true,
    },
    imageboxSize: {
      type: String,
      required: false,
      default: 'medium', // 'medium' | 'small'
    },
    padding: {
      type: String,
      required: false,
      default: 'standard', // 'standard' | 'none'
    },
  },
  computed: {
    rootClasses() {
      return {
        PaddingStandard: this.padding === 'standard',
        PaddingNone: this.padding === 'none',
      }
    },
  },
  components: {
    MultiImage,
  },
}
</script>

<style lang="stylus" scoped>
  .ImageList
    &.PaddingStandard
      background-color $color_grey_lightest
      padding 15px
    &.PaddingNone
      padding 0px

  .Title
    display block
    margin-bottom 5px
    .PaddingNone &
      margin-top 5px
      margin-bottom 0
      font-size 0.875rem

  .EmptyText
    color $color_grey
    font-style italic
    display block
    margin-top 5px

  .ListWrap
    display flex
    flex-wrap wrap
    margin-left -5px
    margin-right -5px

  .ImageWrap
    box(160px)
    margin 5px
</style>

<template>
  <div class="DateSelectModal">
    <span class="Title">{{ modalTitle }}</span>

    <InputField
      name="date"
      type="date"
      :value="date"
      @on-update="onInputUpdate" />

    <div class="ButtonWrap">
      <Button
        :text="mixWB('SAVE')"
        @button-click="onSaveClick" />
    </div>
  </div>
</template>

<script>
import InputField from '@/components/FormElements/InputField.vue'
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'DateSelectModal',
  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    startDate: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      date: '',
    }
  },
  methods: {
    setDateOnLoad() {
      if (!this.startDate) {
        const today = new Date()
        this.date = today.toLocaleDateString('en-CA')
        return
      }

      this.date = this.startDate
    },
    onInputUpdate({ value }) {
      this.date = value
    },
    onSaveClick() {
      this.$emit('pass-to-parent', {
        name: 'date-update',
        value: {
          name: this.name,
          value: this.date,
        },
      })
    },
  },
  components: { InputField, Button },
  created() {
    this.setDateOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .DateSelectModal
    display block

  .Title
    modalTitle()

</style>
